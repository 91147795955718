export const initialStageDataList = [
  {
    // id: "file-1",
    // data: [
    //   {
    //     id: "QxiYE-HAI311pw98Nqtx9",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 120,
    //       height: 36,
    //       fill: "#00000",
    //       x: 544.1693780910495,
    //       y: 158.73013128325812,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "이용방법",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466614233,
    //       "data-frame-type": "text",
    //       id: "QxiYE-HAI311pw98Nqtx9",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: 0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "WjbyaSNYUOU5aSfzaGBcf",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 594,
    //       height: 72,
    //       fill: "#00000",
    //       x: 427.74526621221116,
    //       y: 426.4156259522361,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "3. Also, use Functions left side,\nto edit them",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466733377,
    //       "data-frame-type": "text",
    //       id: "WjbyaSNYUOU5aSfzaGBcf",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "UdkfLoFpAO_HlSZ_Ew_N6",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 864,
    //       height: 72,
    //       fill: "#00000",
    //       x: 354.34018709409236,
    //       y: 516.5653002689967,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "4. Open Export Widget,\nclick to export all frames or just selected one.",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466772214,
    //       "data-frame-type": "text",
    //       id: "UdkfLoFpAO_HlSZ_Ew_N6",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "5ifXoF6yZVQWPGJsey7Z3",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 534,
    //       height: 72,
    //       fill: "#00000",
    //       x: 444.05750601623674,
    //       y: 476.5109155329664,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "3. 또한 왼쪽 기능 버튼을 이용하여 \n원하는대로 이미지 편집하기",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466850323,
    //       "data-frame-type": "text",
    //       id: "5ifXoF6yZVQWPGJsey7Z3",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "xjkWyhZaC6TdXA0RGkBaj",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 804,
    //       height: 72,
    //       fill: "#00000",
    //       x: 370.6524268981184,
    //       y: 380.6691913412044,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "2. 이미지, 도형, 글자, 아이콘 위젯을 같은 방법으로 \n사용하여 나만의 이미지 만들기",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466884810,
    //       "data-frame-type": "text",
    //       id: "xjkWyhZaC6TdXA0RGkBaj",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "4OXbG5FY10agQMWoS2q9i",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 720,
    //       height: 72,
    //       fill: "#00000",
    //       x: 393.4895626237562,
    //       y: 338.51834068115784,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "2. Use Image, Shape, Text, Icon Widgets \nthe same way to make your image",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466891361,
    //       "data-frame-type": "text",
    //       id: "4OXbG5FY10agQMWoS2q9i",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "jx8py6llylT5GTbz6hU0Y",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 582,
    //       height: 72,
    //       fill: "#00000",
    //       x: 431.00771417301564,
    //       y: 285.1928151247437,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "1. 먼저 왼쪽 위젯에서 프레임 클릭 후,\n원하는 프레임을 화면에 끌어다 놓기",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466922628,
    //       "data-frame-type": "text",
    //       id: "jx8py6llylT5GTbz6hU0Y",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "cDPmKp-BOILIYeNxbm32A",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 1056,
    //       height: 72,
    //       fill: "#00000",
    //       x: 302.14101972120807,
    //       y: 574.219202065887,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "4. 내보내기 위젯을 클릭 후 모든 프레임 또는 선택한 프레임 내보내기로\n이미지 파일 다운로드",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466957473,
    //       "data-frame-type": "text",
    //       id: "cDPmKp-BOILIYeNxbm32A",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "ovskJFZssNcMufxWgRIvh",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 594,
    //       height: 72,
    //       fill: "#00000",
    //       x: 427.74526621221116,
    //       y: 234.29393654424274,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "1. Make a Frame by \nclick Frame widget -> drag & drop",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648466988675,
    //       "data-frame-type": "text",
    //       id: "ovskJFZssNcMufxWgRIvh",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.5437413268008741,
    //       scaleY: 0.5437413268008727,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "z1judTJ6niO5bo2PjQtC2",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "icon",
    //       width: 100,
    //       height: 100,
    //       fill: "transparent",
    //       x: 127.2658310703614,
    //       y: 230.57428631575027,
    //       icon: "arrow-left.svg",
    //       brightness: 0,
    //       zIndex: 0,
    //       updatedAt: 1648467131743,
    //       image: {},
    //       "data-frame-type": "icon",
    //       id: "z1judTJ6niO5bo2PjQtC2",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: 0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "MkLAX9k3hPENsc7FNuyrn",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "icon",
    //       width: 100,
    //       height: 100,
    //       fill: "transparent",
    //       x: 161.55662996924025,
    //       y: 64.76852186076843,
    //       icon: "arrow-left.svg",
    //       brightness: 0,
    //       zIndex: 0,
    //       updatedAt: 1648467151238,
    //       image: {},
    //       "data-frame-type": "icon",
    //       id: "MkLAX9k3hPENsc7FNuyrn",
    //       opacity: 1,
    //       rotation: 42.28698762703954,
    //       draggable: true,
    //       scaleX: 0.7511177030169931,
    //       scaleY: 0.7511177030169943,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: -3.4437552843405098e-15,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "zGqLYpi7OBgxkqfkZLQdh",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "icon",
    //       width: 100,
    //       height: 100,
    //       fill: "transparent",
    //       x: 1044.3805464541115,
    //       y: 133.64629163023977,
    //       icon: "arrow-left.svg",
    //       brightness: 0,
    //       zIndex: 0,
    //       updatedAt: 1648467160766,
    //       image: {},
    //       "data-frame-type": "icon",
    //       id: "zGqLYpi7OBgxkqfkZLQdh",
    //       opacity: 1,
    //       rotation: -180,
    //       draggable: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: -0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: -9.394483745001298e-31,
    //       skewY: 0,
    //     },
    //     className: "sample-icon",
    //     children: [],
    //   },
    //   {
    //     id: "SZH04pHF7eR2_CrhiV5ER",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 126,
    //       height: 72,
    //       fill: "#00000",
    //       x: 879.8857694933922,
    //       y: 150.5421600368657,
    //       fontSize: 30,
    //       fontFamily: "'Gamja Flower', cursive",
    //       text: "Widgets\n위젯",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648467186978,
    //       "data-frame-type": "text",
    //       id: "SZH04pHF7eR2_CrhiV5ER",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: 0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "sample-text",
    //     children: [],
    //   },
    //   {
    //     id: "rjeuFJyXiu1VAAyZvdtqK",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 162,
    //       height: 72,
    //       fill: "#00000",
    //       x: 202.37760137206192,
    //       y: 130.98057272399075,
    //       fontSize: 30,
    //       fontFamily: "'Gamja Flower', cursive",
    //       text: "File Tabs\n프로젝트 구분",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648467220105,
    //       "data-frame-type": "text",
    //       id: "rjeuFJyXiu1VAAyZvdtqK",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: 0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "-92dlXYLS5y8CWhO9UqiW",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 162,
    //       height: 72,
    //       fill: "#00000",
    //       x: 208.38654299619634,
    //       y: 248.95184673824173,
    //       fontSize: 30,
    //       fontFamily: "'Gamja Flower', cursive",
    //       text: "Functions\n각종 기능버튼",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648467238137,
    //       "data-frame-type": "text",
    //       id: "-92dlXYLS5y8CWhO9UqiW",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: 0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "Shape",
    //     children: [],
    //   },
    //   {
    //     id: "vlJ-HWh6GwLBrgjfTiOP2",
    //     attrs: {
    //       name: "label-target",
    //       "data-item-type": "text",
    //       width: 270,
    //       height: 36,
    //       fill: "#00000",
    //       x: 487.83555036477446,
    //       y: 121.1742461324082,
    //       fontSize: 30,
    //       fontFamily: "'Jua', sans-serif",
    //       text: "How to use this",
    //       textAlign: "left",
    //       verticalAlign: "top",
    //       zIndex: 0,
    //       brightness: 0,
    //       updatedAt: 1648467790505,
    //       "data-frame-type": "text",
    //       id: "vlJ-HWh6GwLBrgjfTiOP2",
    //       align: "center",
    //       opacity: 1,
    //       rotation: 0,
    //       draggable: true,
    //       visible: true,
    //       scaleX: 0.7511177030169971,
    //       scaleY: 0.7511177030169944,
    //       offsetX: 0,
    //       offsetY: 0,
    //       skewX: 0,
    //       skewY: 0,
    //     },
    //     className: "sample-text",
    //     children: [],
    //   },
    // ],
  },
];
