export default {
  INSERT: {
    FRAME: "INSERT_FRAME",
    IMAGE: "INSERT IMAGE",
    TEXT: "INSERT_TEXT",
    SHAPE: "INSERT SHAPE",
    ICON: "INSERT ICON",
    LINE: "INSERT LINE",
    BORDER: "INSERT BORDER",
  },
};
